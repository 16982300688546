/*


xs - phone
sm
md
lg - tablet / laptop
xl - laptop / desktop
xxl - widescreen

See: https://getbootstrap.com/docs/5.1/layout/breakpoints/

*/
/** HACK: screen height -- why is this not 100vh?! It doesn't seem to be the navbar, which floats over the top
*/
/** select tablets and larger */
/* Use for "mobile only" */
/* Smallest mobile devices */
/* widescreen desktops */
/*

The centralised go-to repository of all colours any Good-Loop app should require
Individual apps can override and add to the colour list in their own files if neccessary,
but if a colour can be sourced from here or added to here it should be! Keep it simple!
Additionally, redesigns can happen, sometimes often - it makes it MUCH easier to execute those
if the css remains centralised, especially colours.
Make sure redesigns happen in these files!

ALSO
When adding or removing a colour, remember to keep track of the bootstrap-style class definitions as well
They should all be commented with matching labels

*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:600,800|Tajawal&display=swap');
.bg-gl-white {
  background-color: #fff !important;
}
.bg-gl-white-blue {
  background-color: #F7FAFC !important;
}
.color-gl-white {
  color: #fff !important;
}
.color-gl-white h1 > a,
.color-gl-white h2 > a,
.color-gl-white h3 > a,
.color-gl-white h4 > a,
.color-gl-white h5 > a {
  color: #fff !important;
}
.color-gl-white-blue {
  color: #F7FAFC !important;
}
.color-gl-white-blue h1 > a,
.color-gl-white-blue h2 > a,
.color-gl-white-blue h3 > a,
.color-gl-white-blue h4 > a,
.color-gl-white-blue h5 > a {
  color: #F7FAFC !important;
}
.bg-gl-red {
  background-color: #770f00 !important;
}
.bg-gl-light-red {
  background-color: #af2009 !important;
}
.bg-gl-impact-red {
  background-color: #A12E1B !important;
}
.color-gl-red {
  color: #770f00 !important;
}
.color-gl-red h1 > a,
.color-gl-red h2 > a,
.color-gl-red h3 > a,
.color-gl-red h4 > a,
.color-gl-red h5 > a {
  color: #770f00 !important;
}
.color-gl-light-red {
  color: #af2009 !important;
}
.color-gl-light-red h1 > a,
.color-gl-light-red h2 > a,
.color-gl-light-red h3 > a,
.color-gl-light-red h4 > a,
.color-gl-light-red h5 > a {
  color: #af2009 !important;
}
.color-gl-impact-red {
  color: #A12E1B !important;
}
.color-gl-impact-red h1 > a,
.color-gl-impact-red h2 > a,
.color-gl-impact-red h3 > a,
.color-gl-impact-red h4 > a,
.color-gl-impact-red h5 > a {
  color: #A12E1B !important;
}
.bg-gl-pink {
  background-color: #FBE1DE !important;
}
.bg-gl-light-pink {
  background-color: #FEF5F4 !important;
}
.color-gl-pink {
  color: #FBE1DE !important;
}
.color-gl-pink h1 > a,
.color-gl-pink h2 > a,
.color-gl-pink h3 > a,
.color-gl-pink h4 > a,
.color-gl-pink h5 > a {
  color: #FBE1DE !important;
}
.color-gl-light-pink {
  color: #FEF5F4 !important;
}
.color-gl-light-pink h1 > a,
.color-gl-light-pink h2 > a,
.color-gl-light-pink h3 > a,
.color-gl-light-pink h4 > a,
.color-gl-light-pink h5 > a {
  color: #FEF5F4 !important;
}
.bg-gl-orange {
  background-color: #af2009 !important;
}
.bg-gl-light-orange {
  background-color: #e82316 !important;
}
.bg-gl-pale-orange {
  background-color: #fcf5f4 !important;
}
.color-gl-orange {
  color: #af2009 !important;
}
.color-gl-orange h1 > a,
.color-gl-orange h2 > a,
.color-gl-orange h3 > a,
.color-gl-orange h4 > a,
.color-gl-orange h5 > a {
  color: #af2009 !important;
}
.color-gl-light-orange {
  color: #e82316 !important;
}
.color-gl-light-orange h1 > a,
.color-gl-light-orange h2 > a,
.color-gl-light-orange h3 > a,
.color-gl-light-orange h4 > a,
.color-gl-light-orange h5 > a {
  color: #e82316 !important;
}
.color-gl-pale-orange {
  color: #fcf5f4 !important;
}
.color-gl-pale-orange h1 > a,
.color-gl-pale-orange h2 > a,
.color-gl-pale-orange h3 > a,
.color-gl-pale-orange h4 > a,
.color-gl-pale-orange h5 > a {
  color: #fcf5f4 !important;
}
.bg-gl-dark-blue {
  background-color: #3c747c !important;
}
.bg-gl-muddy-blue {
  background-color: #286984 !important;
}
.bg-gl-blue {
  background-color: #1894DA !important;
}
.bg-gl-desat-blue {
  background-color: #3488AB !important;
}
.bg-gl-light-blue {
  background-color: #5ca5c0 !important;
}
.bg-gl-lighter-blue {
  background-color: #DEEEF3 !important;
}
.color-gl-dark-blue {
  color: #3c747c !important;
}
.color-gl-dark-blue h1 > a,
.color-gl-dark-blue h2 > a,
.color-gl-dark-blue h3 > a,
.color-gl-dark-blue h4 > a,
.color-gl-dark-blue h5 > a {
  color: #3c747c !important;
}
.color-gl-muddy-blue {
  color: #286984 !important;
}
.color-gl-muddy-blue h1 > a,
.color-gl-muddy-blue h2 > a,
.color-gl-muddy-blue h3 > a,
.color-gl-muddy-blue h4 > a,
.color-gl-muddy-blue h5 > a {
  color: #286984 !important;
}
.color-gl-blue {
  color: #1894DA !important;
}
.color-gl-blue h1 > a,
.color-gl-blue h2 > a,
.color-gl-blue h3 > a,
.color-gl-blue h4 > a,
.color-gl-blue h5 > a {
  color: #1894DA !important;
}
.color-gl-desat-blue {
  color: #3488AB !important;
}
.color-gl-desat-blue h1 > a,
.color-gl-desat-blue h2 > a,
.color-gl-desat-blue h3 > a,
.color-gl-desat-blue h4 > a,
.color-gl-desat-blue h5 > a {
  color: #3488AB !important;
}
.color-gl-light-blue {
  color: #5ca5c0 !important;
}
.color-gl-light-blue h1 > a,
.color-gl-light-blue h2 > a,
.color-gl-light-blue h3 > a,
.color-gl-light-blue h4 > a,
.color-gl-light-blue h5 > a {
  color: #5ca5c0 !important;
}
.bg-gl-darker-grey {
  background-color: #515151 !important;
}
.bg-gl-dark-grey {
  background-color: #928b81 !important;
}
.bg-gl-light-grey {
  background-color: #E4E4E4 !important;
}
.color-gl-darker-grey {
  color: #515151 !important;
}
.color-gl-darker-grey h1 > a,
.color-gl-darker-grey h2 > a,
.color-gl-darker-grey h3 > a,
.color-gl-darker-grey h4 > a,
.color-gl-darker-grey h5 > a {
  color: #515151 !important;
}
.color-gl-dark-grey {
  color: #928b81 !important;
}
.color-gl-dark-grey h1 > a,
.color-gl-dark-grey h2 > a,
.color-gl-dark-grey h3 > a,
.color-gl-dark-grey h4 > a,
.color-gl-dark-grey h5 > a {
  color: #928b81 !important;
}
.color-gl-light-grey {
  color: #E4E4E4 !important;
}
.color-gl-light-grey h1 > a,
.color-gl-light-grey h2 > a,
.color-gl-light-grey h3 > a,
.color-gl-light-grey h4 > a,
.color-gl-light-grey h5 > a {
  color: #E4E4E4 !important;
}
.bg-gl-dark-turquoise {
  background-color: #286984 !important;
}
.bg-gl-turquoise {
  background-color: #51B6AA !important;
}
.color-gl-dark-turquoise {
  color: #286984 !important;
}
.color-gl-dark-turquoise h1 > a,
.color-gl-dark-turquoise h2 > a,
.color-gl-dark-turquoise h3 > a,
.color-gl-dark-turquoise h4 > a,
.color-gl-dark-turquoise h5 > a {
  color: #286984 !important;
}
.color-gl-turquoise {
  color: #51B6AA !important;
}
.color-gl-turquoise h1 > a,
.color-gl-turquoise h2 > a,
.color-gl-turquoise h3 > a,
.color-gl-turquoise h4 > a,
.color-gl-turquoise h5 > a {
  color: #51B6AA !important;
}
.bg-gl-background-default {
  background-color: #f4f3f1 !important;
}
.color-gl-background-default {
  color: #f4f3f1 !important;
}
.color-gl-background-default h1 > a,
.color-gl-background-default h2 > a,
.color-gl-background-default h3 > a,
.color-gl-background-default h4 > a,
.color-gl-background-default h5 > a {
  color: #f4f3f1 !important;
}
.bg-greenmedia-darkgreen {
  background-color: #285E41 !important;
}
.bg-greenmedia-green {
  background-color: #75B752 !important;
}
.bg-greenmedia-lightgreen {
  background-color: #F2F8EF !important;
}
.color-greenmedia-darkgreen {
  color: #285E41 !important;
}
.color-greenmedia-darkgreen h1 > a,
.color-greenmedia-darkgreen h2 > a,
.color-greenmedia-darkgreen h3 > a,
.color-greenmedia-darkgreen h4 > a,
.color-greenmedia-darkgreen h5 > a {
  color: #285E41 !important;
}
.color-greenmedia-green {
  color: #75B752 !important;
}
.color-greenmedia-green h1 > a,
.color-greenmedia-green h2 > a,
.color-greenmedia-green h3 > a,
.color-greenmedia-green h4 > a,
.color-greenmedia-green h5 > a {
  color: #75B752 !important;
}
.color-greenmedia-lightgreen {
  color: #F2F8EF !important;
}
.color-greenmedia-lightgreen h1 > a,
.color-greenmedia-lightgreen h2 > a,
.color-greenmedia-lightgreen h3 > a,
.color-greenmedia-lightgreen h4 > a,
.color-greenmedia-lightgreen h5 > a {
  color: #F2F8EF !important;
}
.bg-greenmedia-seagreen {
  background-color: #477b73 !important;
}
.color-greenmedia-seagreen {
  color: #477b73 !important;
}
.color-greenmedia-seagreen h1 > a,
.color-greenmedia-seagreen h2 > a,
.color-greenmedia-seagreen h3 > a,
.color-greenmedia-seagreen h4 > a,
.color-greenmedia-seagreen h5 > a {
  color: #477b73 !important;
}
.bg-greenmedia-darkcyan {
  background-color: #52727a !important;
}
.color-greenmedia-darkcyan {
  color: #52727a !important;
}
.color-greenmedia-darkcyan h1 > a,
.color-greenmedia-darkcyan h2 > a,
.color-greenmedia-darkcyan h3 > a,
.color-greenmedia-darkcyan h4 > a,
.color-greenmedia-darkcyan h5 > a {
  color: #52727a !important;
}
.fill-gl-red {
  fill: #770f00;
}
.black {
  color: #000;
}
.white {
  color: #fff;
}
.text-dark-0 {
  color: #343a40;
}
.bg-dark-0 {
  background-color: #343a40;
}
.text-light-0 {
  color: #f8f9fa;
}
.bg-light-0 {
  background-color: #f8f9fa;
}
.text-dark-1 {
  color: #3c747c;
}
.bg-dark-1 {
  background-color: #3c747c;
}
.text-light-1 {
  color: #E4E4E4;
}
.bg-light-1 {
  background-color: #E4E4E4;
}
.text-dark-2 {
  color: #770f00;
}
.bg-dark-2 {
  background-color: #770f00;
}
.text-light-2 {
  color: #5ca5c0;
}
.bg-light-2 {
  background-color: #5ca5c0;
}
.color-facebook {
  color: #3b5998;
}
.color-twitter {
  color: #4099ff;
}
.color-instagram {
  color: #e95950;
}
.color-linkedin {
  color: #0077B5;
}
.color-good-loop {
  color: #770f00;
}
.bg-facebook {
  background-color: #3b5998;
}
.bg-twitter {
  background-color: #4099ff;
}
.bg-instagram {
  background-color: #e95950;
}
.bg-linkedin {
  background-color: #0077B5;
}
.bg-good-loop {
  background-color: #770f00;
}
/* To setup: symlink the fonts folder from wwappbase.js into web/fonts
e.g.
	cd web
	ln -s ../src/js/base/base-fonts .
(assuming src/js/base is already a symlink to wwappbase.js/base)
*/
/** This is UPPERCASE so only use it for _short_ headers. 
Otherwise its hard to read, and shouty.
*/
.header-font {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
}
.sub-header-font {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.body-font {
  font-family: "Tajawal", sans-serif;
  font-weight: 400;
}
body {
  font-family: "Tajawal", sans-serif;
  font-weight: 400;
}
h1 {
  font-size: 2.2rem;
  /* from 2.5 */
}
h1.large {
  font-size: 2.5rem;
}
h2 {
  font-size: 1.8rem;
  /* from 2 */
}
h2.large {
  font-size: 2rem;
}
h3 {
  font-size: 1.5rem;
  /* from 1.75 */
}
h3.sm {
  font-size: 1rem;
}
h4 {
  font-size: 1.25rem;
  /* from 1.5 */
}
h4.sm {
  font-size: 1rem;
}
h5 {
  font-size: 1rem;
  /* from 1.25 */
  font-family: "Montserrat", sans-serif;
}
h1,
h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  text-transform: uppercase;
}
h3,
h4,
h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
/* NB ,h3,h4,h5 look too cramped in the Bebas font */
body code {
  color: inherit;
}
/** Opposite of .small 
NB: BS defines .small for textm and -sm -lg for some widgets, but not .large for text */
.large {
  font-size: 1.25em;
}
@media (min-width: 1200px) {
  .large {
    font-size: 1.4em;
  }
}
code {
  text-transform: none !important;
}
.overflow-ellipsis {
  text-overflow: ellipsis;
}
/** 
HACK: copy some BS rules, so we can refer to them here in .less
?? Is there a better way to do this?
*/
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
/** a flex row which does not expand to full width */
.flex-row-compact {
  display: flex;
  flex-direction: row;
}
.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
}
.flex-grow {
  display: flex;
  flex: 1 !important;
}
.flex-vertical-align {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flex-centre {
  align-items: center;
}
.flex-align-items-start {
  align-items: start;
}
.flex-wrap {
  flex-wrap: wrap;
}
/** shorthand for use flex to do vertical centering */
.v-centre {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/** center vertical and horizontal
How does Bootstrap do this?? Maybe use that?? */
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* https://css-tricks.com/snippets/css/a-guide-to-flexbox/ */
.flexbox-1row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}
.flex-space-between {
  justify-content: space-between;
}
/**
BS style support for display:grid
e.g.
<div class="gridbox gridbox-md-3">
defines a 3 column grid
*/
.gridbox {
  display: grid;
  grid-auto-rows: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}
.gridbox.gridbox-gap-5 {
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
}
.gridbox.gridbox-gap-4 {
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
}
.gridbox.gridbox-gap-3 {
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}
.gridbox.gridbox-gap-0 {
  grid-column-gap: 0;
  grid-row-gap: 0;
}
@media (min-width: 576px) {
  .gridbox-sm-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .gridbox-sm-2 {
    grid-template-columns: repeat(2, 2fr);
  }
  .gridbox-sm-3 {
    grid-template-columns: repeat(3, 3fr);
  }
  .gridbox-sm-4 {
    grid-template-columns: repeat(4, 4fr);
  }
  .gridbox-sm-5 {
    grid-template-columns: repeat(5, 5fr);
  }
  .gridbox-sm-6 {
    grid-template-columns: repeat(6, 6fr);
  }
  .gridbox-sm-7 {
    grid-template-columns: repeat(7, 7fr);
  }
}
@media (min-width: 768px) {
  .gridbox-md-1 {
    grid-template-columns: repeat(1, 1fr);
  }
  .gridbox-md-2 {
    grid-template-columns: repeat(2, 2fr);
  }
  .gridbox-md-3 {
    grid-template-columns: repeat(3, 3fr);
  }
  .gridbox-md-4 {
    grid-template-columns: repeat(4, 4fr);
  }
  .gridbox-md-5 {
    grid-template-columns: repeat(5, 5fr);
  }
  .gridbox-md-6 {
    grid-template-columns: repeat(6, 6fr);
  }
  .gridbox-md-7 {
    grid-template-columns: repeat(7, 7fr);
  }
}
/* spinner */
.loader-box {
  padding-top: 50px;
}
.loader {
  border-top: 16px solid #770f00;
  border-right: 16px solid #3c747c;
  border-bottom: 16px solid #af2009;
  border-left: 16px solid #5ca5c0;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
.spinning {
  animation: spin 3s infinite linear;
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.slide {
  transition: transform 1s ease;
}
.slide.up {
  transform: translateY(-100%);
}
.img-credit {
  z-index: 100;
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
  background: #666;
}
/**
BS 5 off-canvas, backport for BS 4
*/
.offcanvas.show {
  transform: none;
  visibility: visible;
}
.offcanvas-start {
  top: 50px;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.5);
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
/* HACK open button */
.offcanvas-toggle {
  position: fixed;
  top: 50px;
  left: 0px;
}
.offcanvas-header .offcanvas-toggle {
  position: absolute;
  left: auto;
  right: 0.5rem;
  top: 0.5rem;
}
.DropZone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border: 2px dashed #666;
  border-radius: 5px;
  cursor: pointer;
}
.DropZone.disabled {
  border-color: #aaa;
}
/* have the save button always visible */
.SavePublishDeleteEtc {
  position: fixed;
  bottom: 0;
  padding: 10px;
  color: white;
  background: #333;
  width: 100%;
  z-index: 100;
}
.panel-heading.btn-link {
  cursor: pointer;
}
/* The loading spinner*/
.loader-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader-box .spinner-box {
  width: 120px;
  height: 120px;
  animation: spin 2s infinite linear;
  border-radius: 50%;
  overflow: hidden;
}
.loader-box.inline {
  flex-direction: row;
}
.loader-box.inline .spinner-box {
  width: 50px;
  height: 50px;
}
.loader-box.inline .loader-text {
  margin-left: 1em;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.emoji {
  font-family: "Noto Color Emoji", "Apple Color Emoji", "Segoe UI Emoji", Times, Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica, serif, LastResort;
  font-variant-emoji: emoji;
  font-size: 2em;
}
.emoji.logo-sm {
  font-size: 1em;
}
.emoji.logo-lg {
  font-size: 6em;
}
/**
	This fixes the issue that an emoji can have weird colours with unpredictable UX effects
	To do different colours, apply more css :'(
*/
.emoji-black {
  color: transparent;
  text-shadow: 0 0 0 black;
}
.emoji-white {
  color: transparent;
  text-shadow: 0 0 0 white;
}
.emoji-grey {
  color: transparent;
  text-shadow: 0 0 0 #666;
}
.emoji-success {
  color: transparent;
  text-shadow: 0 0 0 green;
}
.emoji-info {
  color: transparent;
  text-shadow: 0 0 0 blue;
}
.emoji-warning {
  color: transparent;
  text-shadow: 0 0 0 yellow;
}
.emoji-danger {
  color: transparent;
  text-shadow: 0 0 0 red;
}
.ListLoad {
  margin-bottom: 0.5em;
}
.ListLoad .filter {
  padding-bottom: 0.5em;
  border-bottom: 1px solid #333;
}
.ListLoad .LL-buttons {
  justify-content: space-evenly;
  margin: 0.5em;
}
.ListLoad .pagination {
  justify-content: center;
}
.ListLoad > div:not(:first-child) {
  margin-top: 0.5em;
}
.ListItem {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
}
.ListItem > *:not(:first-child) {
  margin-left: 0.5em;
}
.ListItem .id {
  font-family: monospace;
}
.ListItem.btn-space {
  width: calc(100% - 4em);
}
.TaskList {
  margin-left: 1em;
  margin-bottom: 1em;
  border-left: 1px solid #666;
  padding-left: 1em;
  background: white;
  height: 100%;
  max-width: 350px;
  min-width: 200px;
}
.login-guts {
  /**
	wide login buttons
	*/
}
.login-guts .login-divs {
  display: flex;
}
.login-guts .btn.signin {
  width: 100%;
  text-align: left;
}
.switch-verb {
  float: right;
  text-align: right;
}
@media (max-width: 576px) {
  .login-modal #loginByEmail label {
    display: none;
  }
  .login-modal .modal-title {
    font-size: 1rem;
    line-height: 1.25;
  }
  .login-modal .modal-title .logo {
    height: 2.5em;
    width: 2.5em;
  }
}
.MDText {
  /* handle long urls nicely */
  overflow-wrap: anywhere;
}
.MessageBar {
  position: fixed;
  /* below dialogs, but above the page base */
  z-index: 101;
}
.MessageBar .alert {
  overflow-x: scroll;
  max-height: 25em;
  position: relative;
}
.MessageBar .alert .close {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}
.MessageBar.side {
  right: 0;
  bottom: 0;
  width: 25em;
}
/* a more compact form layout than BS standard (see also form-inline) */
.form-compact .form-group {
  margin-bottom: 0.85rem;
}
.form-compact label {
  margin-bottom: 0rem;
}
.form-compact .form-control:not(.btn-lg, .btn-sm),
.form-compact .btn:not(.btn-lg, .btn-sm) {
  padding: 0.55rem 0.35rem 0.15rem 0.35rem;
  line-height: 1;
  height: calc(1em + 0.7rem + 2px);
}
.form-compact .form-control[type="color"] {
  padding: 0;
}
.form-compact .input-group-append,
.form-compact .input-group-prepend {
  height: calc(1em + 0.7rem + 2px);
}
.form-compact input.form-check-input {
  margin-top: 0;
}
.form-compact .card-header.btn {
  height: unset;
}
/* ./form-compact */
/* checkbox layout vs sizing - BS default looks a bit broken */
.form-control-sm {
  height: 1.3em;
  line-height: 1;
}
select.form-control-sm {
  height: 1.5em;
  padding: 0rem 0.25rem;
}
.form-group .input-group.input-group-sm .form-control {
  height: calc(1.3em + 0.4rem + 2px);
  line-height: 1.3;
  padding-top: 0.5rem;
}
.form-group .input-group.input-group-sm .input-group-prepend,
.form-group .input-group.input-group-sm .input-group-append,
.form-group .input-group.input-group-sm .btn {
  height: calc(1.3em + 0.4rem);
  line-height: 1.3;
}
.form-control-lg {
  height: 1.5em;
}
.form-group.Money input,
.form-group.email input,
.form-group.imgUpload input,
.form-group.videoUpload input,
.form-group.textarea textarea {
  max-width: 40em;
}
.form-group.imgUpload .video-thumbnail,
.form-group.videoUpload .video-thumbnail,
.form-group.bothUpload .video-thumbnail,
.form-group.imgUpload .img-thumbnail,
.form-group.videoUpload .img-thumbnail,
.form-group.bothUpload .img-thumbnail,
.form-group.imgUpload .DropZone,
.form-group.videoUpload .DropZone,
.form-group.bothUpload .DropZone {
  margin: 5px;
  height: 80px;
}
.form-group.imgUpload .DropZone,
.form-group.videoUpload .DropZone,
.form-group.bothUpload .DropZone {
  margin-left: 0;
  width: 96px;
}
.form-group.imgUpload .preview-container,
.form-group.videoUpload .preview-container,
.form-group.bothUpload .preview-container {
  height: 90px;
  max-width: calc(100% - (96px + 5px + 5px));
}
.form-group.imgUpload .preview-container img,
.form-group.videoUpload .preview-container img,
.form-group.bothUpload .preview-container img,
.form-group.imgUpload .preview-container video,
.form-group.videoUpload .preview-container video,
.form-group.bothUpload .preview-container video {
  margin-right: 0;
}
.form-group.imgUpload .img-thumbnail.stripe-bg,
.form-group.videoUpload .img-thumbnail.stripe-bg,
.form-group.bothUpload .img-thumbnail.stripe-bg {
  background: repeating-linear-gradient(-45deg, #555, #555 6px, #ccc 6px, #ccc 12px);
}
.form-group.imgUpload .video-thumbnail,
.form-group.videoUpload .video-thumbnail,
.form-group.bothUpload .video-thumbnail {
  width: unset;
}
.keyset .keys {
  margin-bottom: 1em;
}
.keyset .key {
  padding: 0.25em;
  border: 1px solid grey;
  border-radius: 0.25em;
  margin-right: 0.25em;
}
.keyset .remove-key {
  background-color: black;
  color: white;
  margin-left: 0.25em;
  border-radius: 0.25em;
  cursor: pointer;
}
.pills .form-control input {
  border: none;
}
.pills .form-control input:focus {
  border: none;
}
/** PropControlList */
.list ul.rowStyle {
  padding: 0;
}
.list ul.rowStyle li {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  margin: 0.25em;
  display: flex;
  flex-direction: row;
}
/** Make the inner wrapper of the autocomplete element a position reference for the dropdown menu */
.autocomplete > div {
  position: relative;
}
/**
This mimics Bootstrap's form-control on-focus.
You can apply it to, well, anything.
*/
.focus {
  border-radius: 4px;
  border: 1px solid #ccc;
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  margin: -1px;
}
/* aggressively hide filtered inputs! */
.nomatch {
  display: none;
}
/* Special styling for colour controls */
.color-control {
  position: relative;
}
.color-control .overlay {
  pointer-events: none;
  position: absolute;
  z-index: 9;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 1px 1px 1px black;
}
.color-control .overlay.light-bg {
  color: black;
  text-shadow: 1px 1px 1px white;
}
.color-control .overlay.no-color {
  background: repeating-linear-gradient(-45deg, #555, #555 6px, #ccc 6px, #ccc 12px);
}
/* ./color-control */
.data-item-control .dropdown-sizer {
  position: relative;
  display: flex;
}
.data-item-control .btn,
.data-item-control .btn-group {
  overflow: hidden;
  max-width: 100%;
}
.data-item-control .btn.clear,
.data-item-control .btn-create {
  flex-shrink: 0;
}
.data-item-control .DataItemBadge {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.data-item-control .DataItemBadge .logo {
  border: 1px solid #ddd;
  vertical-align: middle;
}
.data-item-control span.preview .DataItemBadge {
  cursor: default;
}
.data-item-control .items-dropdown {
  display: block;
  position: absolute;
  /* min/max width: size to the text input - but also allow overflow for small input vs showing the names */
  min-width: 100%;
  max-width: 20rem;
  top: 100%;
  margin-top: 0.25em;
  left: 0;
  z-index: 999;
}
.data-item-control .items-dropdown .DataItemBadge {
  display: block;
  margin: 0;
  padding: 0.25em 0;
  cursor: pointer;
}
.data-item-control .items-dropdown .DataItemBadge:not(:first-child) {
  border-top: 1px solid #ddd;
}
.form-compact .data-item-control .btn {
  padding: 0;
}
.form-compact .data-item-control .DataItemBadge {
  height: 100%;
  padding: 0 0.25rem;
}
.form-compact .data-item-control .DataItemBadge .logo {
  height: 100%;
  border: none;
  margin-right: 0.25rem;
  width: unset;
  max-width: 2rem;
}
/* ./data-item-control */
/* Modals */
.modal-content {
  /* if text is in in the modal, make it take up enough space */
}
.modal-content textarea,
.modal-content text {
  max-width: 100% !important;
  width: 100%;
}
.form-group .toggle-switch .btn {
  width: 1.5rem;
  transition: margin 0.1s linear;
  pointer-events: none;
}
.btn.data-modified {
  background: repeating-linear-gradient(-45deg, #000, #000 4px, #ffc107 4px, #ffc107 8px);
}
.btn.data-modified,
.form-compact .btn.data-modified {
  padding: 0;
  width: 1em;
  height: 1em;
}
.data-modified-details .diff-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}
.data-modified-details > :not(.diff-val) {
  flex-shrink: 0;
}
.data-modified-details .diff-val {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.SimpleTable {
  /** by default, dont allow too big columns */
  /* styling for tree children */
  /* e.g. "Page < 1 > of 3" */
}
.SimpleTable td {
  max-width: 33vw;
  overflow: auto;
  overflow-wrap: anywhere;
}
.SimpleTable tr.depth2 td:first-child,
.SimpleTable tr.depth3 td:first-child,
.SimpleTable tr.depth4 td:first-child,
.SimpleTable tr.depth5 td:first-child,
.SimpleTable tr.depth6 td:first-child,
.SimpleTable tr.depth7 td:first-child {
  /* make the before content sit to the left of the text without a linebreak */
  flex-direction: row;
  display: flex;
}
.SimpleTable tr.depth2 td:first-child::before,
.SimpleTable tr.depth3 td:first-child::before,
.SimpleTable tr.depth4 td:first-child::before,
.SimpleTable tr.depth5 td:first-child::before,
.SimpleTable tr.depth6 td:first-child::before,
.SimpleTable tr.depth7 td:first-child::before {
  margin-right: 0.2rem;
}
.SimpleTable tr.depth2 {
  font-size: 90%;
}
.SimpleTable tr.depth2 td:first-child::before {
  margin-left: 0.1rem;
  content: '>';
}
.SimpleTable tr.depth3 {
  font-size: 80%;
}
.SimpleTable tr.depth3 td:first-child::before {
  margin-left: 0.45rem;
  content: '>>';
}
.SimpleTable tr.depth3 td {
  padding: 0.5rem;
}
.SimpleTable tr.depth4,
.SimpleTable tr.depth5,
.SimpleTable tr.depth6,
.SimpleTable tr.depth7 {
  font-size: 75%;
}
.SimpleTable tr.depth4 td:first-child::before,
.SimpleTable tr.depth5 td:first-child::before,
.SimpleTable tr.depth6 td:first-child::before,
.SimpleTable tr.depth7 td:first-child::before {
  margin-left: 0.65rem;
  content: '>>>';
}
.SimpleTable tr.depth4 td,
.SimpleTable tr.depth5 td,
.SimpleTable tr.depth6 td,
.SimpleTable tr.depth7 td {
  padding: 0.4rem;
}
.SimpleTable .topRow {
  font-weight: bold;
}
.SimpleTable .pager a {
  text-decoration: none;
}
.table p {
  padding-bottom: 0.25em;
}
.SimpleTable .table a {
  word-break: break-word;
}
.SimpleTable .selected {
  background-color: #e7f0fd;
}
.SimpleTable.scroller {
  width: 100%;
  height: 100%;
}
.SimpleTable.scroller .scroll-div {
  width: 100%;
  height: 100%;
  overflow: scroll;
}
.WizardProgressWidget {
  display: flex;
  justify-content: center;
  align-items: center;
}
.WizardProgressWidget .Stage {
  position: relative;
  display: inline-block;
  /* evenly spaced */
  flex-grow: 1;
  flex-basis: 0;
  color: #80bde7;
}
.WizardProgressWidget .Stage h5 {
  font-size: 18px;
  transition: opacity 0.5s;
}
.WizardProgressWidget .Stage .graphic {
  position: relative;
}
.WizardProgressWidget .Stage .marker {
  position: relative;
  left: 50%;
  margin-left: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  z-index: 1;
}
.WizardProgressWidget .Stage .line {
  position: absolute;
  top: 15px;
  width: 100%;
  height: 0;
  outline: 2px solid grey;
  z-index: 0;
}
.WizardProgressWidget .Stage:first-child .line,
.WizardProgressWidget .Stage:last-child .line {
  width: 50%;
}
.WizardProgressWidget .Stage:first-child .line {
  left: 50%;
}
.WizardProgressWidget .Stage.complete {
  cursor: pointer;
}
.WizardProgressWidget .Stage .below {
  opacity: 0;
}
.WizardProgressWidget .Stage.active .above {
  opacity: 0;
}
.WizardProgressWidget .Stage.active .below {
  opacity: 1;
}
.WizardProgressWidget .Stage .marker {
  background-color: #80bde7;
}
.WizardProgressWidget .Stage.complete,
.WizardProgressWidget .Stage.active {
  color: #005;
}
.WizardProgressWidget .Stage.complete .marker,
.WizardProgressWidget .Stage.active .marker {
  background-color: #005;
}
.padded-block {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}
.WizardStage {
  min-height: 50vh;
}
.WizardStage .nav-buttons {
  margin-top: 10px;
}
.WizardStage .nav-buttons-top {
  margin-top: 0;
  border-bottom: 1px solid #333;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}
.footer {
  border-top: 1px solid #999;
  background: #333;
  color: white;
  font-size: 11pt;
  line-height: 1;
  min-height: 2em;
  padding: 0.5em;
}
.footer a {
  color: #5ca5c0;
}
.Ticker {
  font-variant-numeric: tabular-nums;
}
.Ticker span {
  text-align: center;
  margin: unset;
}
.BSCarousel {
  height: 100%;
}
.BSCarousel .carousel,
.BSCarousel .carousel-inner,
.BSCarousel .carousel-item {
  height: 100%;
}
.BSCarousel .carousel-control-next-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>");
}
.BSCarousel .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}
@keyframes toast-intro {
  0% {
    transform: translate(-50%, 10rem);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}
@keyframes toast-outro {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 10rem);
    opacity: 0;
  }
}
@keyframes toast-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.gl-toast {
  background-color: #515151;
  color: white;
  padding: 0 1.5rem;
  font-size: 1.5rem;
  border-radius: 10px;
  position: fixed;
  z-index: 9999;
  bottom: 5rem;
  left: 50%;
  opacity: 0;
}
.gl-toast.show {
  animation: toast-intro 0.5s ease-in;
  animation-fill-mode: forwards;
}
.gl-toast.hide {
  animation: toast-outro 0.5s ease-out;
  animation-fill-mode: forwards;
}
.gl-toast .logo {
  margin-right: 1rem;
  width: 3rem;
  animation: toast-logo-spin 2s linear;
  animation-iteration-count: infinite;
}
a {
  cursor: pointer;
}
.TODO {
  display: none;
}
.avoid-navbar {
  margin-top: 70px;
}
.navbar-brand {
  padding: 2px;
}
.navbar-brand img {
  min-height: 50%;
  max-height: 48px;
}
/** an extra small button (btn-sm is too big for a 1-line-height setting*/
.btn.btn-xs {
  padding: 0.1rem 0.2rem;
  line-height: 1;
}
/** I miss BS3's btn-default in BS4 -- note btn-outline-dark is a decent replacement */
.btn-default {
  color: #343a40;
  border-color: #343a40;
  background-color: #f8f9fa !important;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active {
  color: #333;
  background-color: #e6e6e6;
}
.btn-default:hover {
  border-color: #adadad;
}
.btn-default:focus {
  border-color: #8c8c8c;
}
.btn-default:active {
  background-image: none;
  border-color: #adadad;
}
.btn.inset {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3) inset;
}
/* avoid large X with odd spacing on badges */
.badge .close {
  font-size: inherit;
}
.alert.small {
  padding: 0.35rem 0.6rem;
  margin-bottom: 0.35rem;
  line-height: 1.2;
}
.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.checkbox input {
  margin-top: 0;
}
.no-margin {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.no-margin-bottom {
  margin-bottom: 0px;
}
.logo {
  width: 4em;
  height: 4em;
}
.logo-xs,
img.logo-xsmall,
.logo.logo-xs {
  width: 1em;
  height: 1em;
}
.logo-sm,
img.logo-small,
.logo.logo-sm {
  width: 2em;
  height: 2em;
}
.logo-lg,
img.logo-large,
.logo.logo-lg {
  width: 6em;
  height: 6em;
}
.logo-xl,
.logo-xlarge,
.logo.logo-xl {
  width: 8em;
  height: 8em;
}
img.logo,
img.logo-xs,
img.logo-xsmall,
img.logo-sm,
img.logo-small,
img.logo-lg,
img.logo-large,
img.logo-xl,
img.logo-xlarge {
  object-fit: contain;
}
/** a generic .disabled effect */
.disabled {
  opacity: 75%;
}
/** marker for dev-only debug/admin elements that users do not see. See DevOnly.jsx */
.dev-only {
  position: relative;
  background-color: #f0fff0;
  padding: 0.15rem;
  margin: -0.15rem;
  border: 1px dashed #ddd;
}
.dev-only > * {
  opacity: 75%;
}
.dev-only .dev-only-marker {
  font-size: 0.66rem;
  font-family: sans-serif;
  position: absolute;
  bottom: 0;
  right: 0.2rem;
  pointer-events: none;
  opacity: 0.5;
}
/** End marker for dev-only */
.row .page {
  width: 100%;
}
@media (max-width: 767px) {
  #mainDiv.nav-open {
    overflow: hidden;
    height: 100vh;
  }
}
@media (min-width: 576px) {
  .container .full-width {
    margin-left: calc(0.5 * (540px + 15px - 100vw));
    margin-right: calc(0.5 * (540px + 15px - 100vw));
  }
}
@media (min-width: 768px) {
  .container .full-width {
    margin-left: calc(0.5 * (720px + 15px - 100vw));
    margin-right: calc(0.5 * (720px + 15px - 100vw));
  }
}
@media (min-width: 992px) {
  .container .full-width {
    margin-left: calc(0.5 * (960px + 15px - 100vw));
    margin-right: calc(0.5 * (960px + 15px - 100vw));
  }
}
@media (min-width: 1200px) {
  .container .full-width {
    margin-left: calc(0.5 * (1140px + 15px - 100vw));
    margin-right: calc(0.5 * (1140px + 15px - 100vw));
  }
}
